.landingVideo {
    height: 0;
    width:100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
}


.landingVideo iframe {
    border: 0
}

.landingVideo iframe,
.landingVideo object,
.landingVideo embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}